import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import { HTMLContent } from "../components/Content";
import { getImage } from "gatsby-plugin-image";
import FullWidthImage from "../components/FullWidthImage";
import ScrollDivider from "../components/ScrollDivider";
import instagram from "../img/social/instagram.svg";

// eslint-disable-next-line
export const ContactPageTemplate = ({
  heading,
  subheading,
  color,
  members,
}) => {
  return (
    <section
      className="section section--gradient"
      style={{ background: color }}
    >
      <div className="container" id="main">
        <div className="section">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="content contact-content">
                <div className="columns is-multiline">
                  <div className="column is-12">
                    <h3 className="has-text-weight-semibold is-size-3">
                      {heading}
                    </h3>
                    <h4>{subheading}</h4>
                    <hr className="solid thick"></hr>
                  </div>
                  {members.map((member) => (
                    <div className="column is-4" key={member.id}>
                      <div className={`member-list`}>
                        <h5 className="title">{member.function}</h5>
                        <p className="content">{member.name}</p>
                        <a href={"mailto:" + member.mail} className="content">
                          {member.mail}
                        </a>
                      </div>
                    </div>
                  ))}
                  <div className="column is-12 has-text-centered">
                    <hr className="solid thick"></hr>
                    <a
                      title="instagram"
                      rel="noreferrer"
                      target="_blank"
                      href="https://instagram.com/skadilustrum"
                    >
                      <img
                        src={instagram}
                        alt="Instagram"
                        style={{
                          width: "3rem",
                          height: "3rem",
                          filter: "brightness(0) invert(1)",
                        }}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

ContactPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
  color: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
};

const ContactPage = ({ data }) => {
  const { markdownRemark: post } = data;
  const heroImage = getImage(post.frontmatter.image) || post.frontmatter.image;

  return (
    <Layout>
      <FullWidthImage
        img={heroImage}
        title={post.frontmatter.title}
        height={"80vh"}
        color="#E8E100"
        darken={true}
      />
      <ScrollDivider
        color={post.frontmatter.color}
        button={true}
        anchor="/contact#main"
      />
      <ContactPageTemplate
        contentComponent={HTMLContent}
        color={post.frontmatter.color}
        heading={post.frontmatter.heading}
        subheading={post.frontmatter.subheading}
        members={post.frontmatter.members}
      />
    </Layout>
  );
};

ContactPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ContactPage;

export const contactPageQuery = graphql`
  query ContactPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        color
        heading
        subheading
        members {
          function
          name
          mail
        }
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;
